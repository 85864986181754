@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #333; /* Dark gray background */
  color: #fff; /* White text */
  font-family: 'Space Mono', monospace; /* Google Fonts Space Mono */
}

.container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
}

.card {
  background-color: #444; /* Dark gray card background */
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

input[type="text"] {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  background-color: #1e90ff; /* Dodger blue button color */
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc; /* Disabled button color */
  cursor: not-allowed;
}

.response-container {
  margin-top: 20px;
}

h1,
h2,
h3 {
  margin-bottom: 10px;
}

p {
  margin: 5px 0;
}
